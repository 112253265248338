import React, { Component } from 'react';
import './Lifts-test.css';
import axios from 'axios';
import Loader from 'react-loader-spinner';

const LoadingIndicator = props => {
    return (
    <div style={{
        width: "100%",
        height: "100",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
        }}
    >
    <Loader type="ThreeDots" color="var(--liftopenbox)" height="50" width="50" />
    </div>
    ) 
}

class Liftstest extends Component {
    constructor () {
        super();
        this.state = {
            vLifts: [],
            vDate: '',
            loading: true,
            error: false,
        };
    }

    async componentDidMount() {

        await axios.get('https://fn.wblift.app/api/?type=terrain')
        // await axios.get('https://wbliftfunction.azurewebsites.net/api/GetTerrain')
        
        .then(response => {
            var LiftArray = [];

            response.data.Lifts.forEach((el, i) => {
                if (el.WaitTimeInMinutes == null){
                    var LiftWait = "";
                } else {
                    var LiftWait = el.WaitTimeInMinutes;
                }
                if (el.Status == "Open"){
                    var Indicator = String.fromCodePoint(0x1f7e2);
                }
                else if (el.Status == "OnHold"){
                    var Indicator = String.fromCodePoint(0x1f7e0);
                }
                else {
                    var Indicator = String.fromCodePoint(0x1f534);
                }
                LiftArray[i] = {id: i, Name: el.Name, Mountain: el.Mountain, Status: el.Status, WaitTime: LiftWait, Indicator: Indicator}
            })

            this.setState({ vLifts: LiftArray});
            this.setState({ vDate: new Date(response.data.Date).toLocaleString('en-US',{timeZoneName: "short"})});
            this.setState({ loading: false });    
            })
        .catch(error => {
            console.log(error);
            console.log("Error Here, sending Error to client");
            this.setState({ loading: false });
            this.setState({ error: true})
        });

    }

    render() {
        const { loading } = this.state;
        const { error } = this.state;
        var vLifts = this.state.vLifts;
        var mapBlkLifts = vLifts.map(function(vLifts){
            if (vLifts.Mountain === "Blackcomb Lifts"){
                if (vLifts.WaitTime != 0){
                    var waitrow = vLifts.WaitTime;
                }
                else {
                    
                }
                return  (<React.Fragment key={vLifts.id}>
                        <tr>
                            <th id={vLifts.Status}>
							<div class="liftname">{vLifts.Name}</div>
							<div class="waitmins">{waitrow}</div>
							</th>
                            {/* <th id="waittime">{vLifts.WaitTime}</th> */}
                        </tr>
                        </React.Fragment>)
            }
        })
        var mapWhLifts = vLifts.map(function(vLifts){
            if (vLifts.Mountain === "Whistler Lifts"){
                if (vLifts.WaitTime != 0){
                    var waitrow = vLifts.WaitTime;
                }
                else {
                    
                }
                return  (<React.Fragment key={vLifts.id}>
                        <tr>
                            <th id={vLifts.Status}>
							<div class="liftname">{vLifts.Name}</div>
							<div class="waitmins">{waitrow}</div>
							</th>
                            {/* <th id="waittime">{vLifts.WaitTime}</th> */}
                        </tr>
                        </React.Fragment>)
            }
        })


        return (
            <div className="today--section container">
                {/* <h2>Lift Information</h2> */}
                { error ? 
                // error is true:
                <div className="columns today--section__box">
                    <div className="column lifts--section">
                        <h4 id="error">Error gathering lift data...</h4>
                    </div>
                </div>
                :
                // error is false:
                <div className="columns today--section__box">
                    <div className="column lifts--section">
                    <h5>Blackcomb</h5>
                        { loading ? <LoadingIndicator /> : 
                            <table><thead></thead><tbody>{mapBlkLifts}</tbody></table>
                        }
                    </div>
                    <div className="column liftsend--section">
                        <h5>Whistler</h5>
                        { loading ? <LoadingIndicator /> : 
                            <table><thead></thead><tbody>{mapWhLifts}</tbody></table>
                        }   
                    </div>
                </div>
                }
                <h6>Updated: {this.state.vDate}</h6>
            </div>
        )
    }
}

export default Liftstest
