import React from 'react';
import { Link } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu'




    const Navigation = (props) => {
      function toggleBurgerMenu() {
        document.querySelector('.navbar-menu').classList.toggle('is-active');
      }

    
  
    return (
      <nav className='navbar' role='navigation' aria-label='main navigation'>
        <div className='navbar-brand'>
          <a href='/' className='navbar-item'>
            <img
              src='/logo192.png'
              alt='Logo'
              width='WBLift'
              height='40'
            />
          </a>

          <a role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasic"
          onClick={toggleBurgerMenu}>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
        </div>
        <div id='navbarBasic' className='navbar-menu'>
          
            <div className='navbar-end'>
            <Link className='navbar-item' onClick={toggleBurgerMenu} to='/'>
                Home
              </Link>
              <Link className='navbar-item' onClick={toggleBurgerMenu} to='/lift'>
                Lift
              </Link>
              <Link className='navbar-item' onClick={toggleBurgerMenu} to='/snow'>
                Snow
              </Link>
              <Link className='navbar-item' onClick={toggleBurgerMenu} to='/grooming'>
                Grooming
              </Link>
              <Link className='navbar-item' onClick={toggleBurgerMenu} to='/biketrails'>
                Bike Trails
              </Link>
            </div>
          
        </div>
      </nav>
    )
  }

  export default Navigation;