import React, { Component } from 'react';
import './Summer.css';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import { Parser } from 'htmlparser2';
// import xml2js from 'xml2js';

const LoadingIndicator = props => {
    return (
    <div style={{
        width: "100%",
        height: "100",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
        }}
    >
    <Loader type="ThreeDots" color="var(--liftopenbox)" height="50" width="50" />
    </div>
    ) 
}

class SummerTrails extends Component {
    constructor () {
        super();
        this.state = {
            vTrails: [],
            vZones: [],
            vBikeParkNotes: '',
            vDate: '',
            vLifts: [],
            vWeather: [],
            loading: true,
            error: false,
            liftloading: true,
            lifterror: false,
            weatherloading: true,
            weathererror: false,
            search: null,
        };
    }

    componentDidMount() {
        // Step 1: Make the HTTP request
        axios.get('https://fn.wblift.app/api/?type=summer')
        .then(response => {
            const xml = response.data;
            // Step 2: Set up the HTMLParser2
            let liftjson = [];
            var LiftArray = [];
            
            const parser = new Parser({
                onopentag(name, attribs) {
                    // Handle opening tags
                    if (name === 'lift') {
                        // Add each lift to the liftjson
                        liftjson.push(attribs);
                    }
                },
                onend() {
                    // Processing the parsed lifts
                    liftjson.forEach((el, i) => {
                        if (el.status == "1"){ //Open
                            var StatIcon = <i className="fa-solid fa-circle-check"></i>
                        }
                        if (el.status == "0"){ //Closed
                            var StatIcon = <i className='fa-solid fa-circle-xmark'></i>
                        }
                        if (el.Mountain == "Whistler"){
                            LiftArray.push({
                                ID: i, 
                                Lift: el.name, 
                                Mountain: el.Mountain, 
                                MountainID: el.MountainID, 
                                Type: el.lift_type, 
                                RideTime: el.lift_ride_time, 
                                isOpen: el.status, 
                                StatusIcon: StatIcon
                            })
                        }
                    })

                    // Sorts Trails Array to A-Z
                    LiftArray.sort((a, b) => {
                        if (a.Lift < b.Lift) return -1;
                        if (a.Lift > b.Lift) return 1;
                        return 0;
                    });
                },
                onerror(error) {
                    // Handle errors
                    console.error(error);
                }
            }, { xmlMode: true });

            // Step 3: Parse the XML
            parser.write(xml);
            parser.end();
            
            // Adds information to Constructor States
            this.setState({ vLifts: LiftArray });
            this.setState({ liftloading: false })

        })
        .catch(error => {
            console.log(error);
            console.log("Error Here, sending Error to client");
            this.setState({ liftloading: false });
            this.setState({ lifterror: true})
        });



        // Step 1: Make the HTTP request
        axios.all([
            axios.get('https://fn.wblift.app/api/?type=weatherstation&id=160'),
            axios.get('https://fn.wblift.app/api/?type=weatherstation&id=210'),
            axios.get('https://fn.wblift.app/api/?type=weatherstation&id=201'),
        ])
        .then(axios.spread((obj1, obj2, obj3) => {
            // Step 2: Set up the HTMLParser2
            const objarray = [obj1.data, obj2.data, obj3.data];
            const WeatherArray = [];
        
            objarray.forEach((el, i) => {
                let weatherjson = null;
                
                const parser = new Parser({
                    onopentag(name, attributes) {
                        // Parse WeatherStation Name and ID
                        if (name === "weatherstation") {                            
                            weatherjson = { $: attributes, Data: [] };
                        }
                        // Parse WeatherStation Temperature Information
                        if (name === "data" && weatherjson) {
                            weatherjson.Data.push({ $: attributes });
                        }
                    }
                }, { decodeEntities: true });
        
                // Step 3: Parse the XML
                parser.write(el);
                parser.end();
        
                if (weatherjson) {
                    WeatherArray.push({
                        ID: i,
                        Location: weatherjson.$.name,
                        CurrentTemp: weatherjson.Data[0].$.temp + "°C",
                        FriendlyUpdated: weatherjson.Data[0].$.friendlyupdated,
                        Updated: weatherjson.Data[0].$.updated
                    });
                }
            });
            // Adds information to Constructor States
            this.setState({ vWeather: WeatherArray });
            this.setState({ weatherloading: false });
        }))
        .catch(error => {
            console.log(error);
            console.log("Error Here, sending Error to client");
            this.setState({ weatherloading: false });
            this.setState({ weathererror: true });
        });



        axios.get('https://fn.wblift.app/api/?type=bike')
        
        .then(response => {
            // Converts data to usable JSON
            var jsonpData = response.data.replace(/^[^\{]+/, '').replace(/[^\}]+$/, '');
            var bikeparktrails = JSON.parse(jsonpData)
            var AlsoCreekAccessible = [
                'Blueberry Bathtub',
                'D1',
                'Drop In Clinic',
                'Duffman',
                'Expressway',
                'Freight Train - Lower',
                'Little Alder',
                'Mackenzie River',
                'More Duff',
                'No Duff',
                'No Joke - Lower',
                'Renegade',
                'Side Track',
                'Too Tight',
                'Weasel Juice'
            ]
                    
            var TrailArray = [];

                bikeparktrails.Trails.forEach((el, i) => {
                    if (el.Difficulty == "Green"){
                        var DifficultyIcon = <i className="fa-solid fa-circle"></i>
                    }
                    if (el.Difficulty == "Blue"){
                        var DifficultyIcon = <i className="fa-solid fa-square"></i>
                    }
                    if (el.Difficulty == "Black"){
                        var DifficultyIcon = <i className="fa-solid fa-diamond"></i>
                    }
                    if (el.Difficulty == "Double Black"){
                        var DifficultyIcon = <><i className="fa-solid fa-diamond"></i><i className="fa-solid fa-diamond"></i></>
                    }
                    if (el.Difficulty == "Pro-Line"){
                        var DifficultyIcon = <i className="fa-solid fa-caret-up"></i>
                    }
                    if (el.WORKDONEIND == "1") {
                        var GroomIcon = <i className="fa-solid fa-person-digging"></i>
                    }
                    if (el.Status == "Open"){
                        var StatIcon = <i className="fa-solid fa-circle-check"></i>
                    }
                    if (el.Status != "Open"){
                        var StatIcon = <i className='fa-solid fa-circle-xmark'></i>
                    }

                    {
                    // Adds trails to array with * if accessible from Creekside Gondy Aswell
                    if (el.ZoneName == "Garbanzo" && AlsoCreekAccessible.includes(el.TrailName)){
                        TrailArray.push({ID: i, Trail: el.TrailName+' *', Zone: el.ZoneName, Difficulty: el.Difficulty, Icon: DifficultyIcon, isOpen: el.Status, StatusIcon: StatIcon, Groomed: el.WORKDONEIND, GroomingIcon: GroomIcon})
                        }
                    else
                    // Adds trails to array
                        {
                        TrailArray.push({ID: i, Trail: el.TrailName, Zone: el.ZoneName, Difficulty: el.Difficulty, Icon: DifficultyIcon, isOpen: el.Status, StatusIcon: StatIcon, Groomed: el.WORKDONEIND, GroomingIcon: GroomIcon})
                        }
                    }
                    
                    
                    
                })

            // Sorts Trails Array to A-Z
            TrailArray = TrailArray.sort((a, b) => {
                if (a.Zone < b.Zone) return -1;
                if (a.Zone > b.Zone) return 1;
                if (a.Trail < b.Trail) return -1;
                if (a.Trail > b.Trail) return 1;
                return 0;
            });

            var zones = Array.from(
                new Set(TrailArray.map(item => item.Zone))
            ).map(Zone => {
                return {Zone}
            })
            zones.push({Zone: "All"})

            // Sorts Zones Array to A-Z
            zones = zones.sort((a, b) => {
                if (a.Zone < b.Zone) return -1;
                if (a.Zone > b.Zone) return 1;
                return 0
            })

            // Get Update Date
            var date_extract = parseInt(bikeparktrails.UpdateDate.match(/\d+/)[0]);

            // Sets Variables for use later
            this.setState({ vTrails: TrailArray })
            this.setState({ vZones: zones })
            this.setState({ vBikeParkNotes: bikeparktrails.Notes })
            this.setState({ vDate: new Date(date_extract).toLocaleString('en-US',{timeZoneName: "short"})});
            this.setState({ loading: false }); 

            })
        .catch(error => {
            console.log(error);
            console.log("Error Here, sending Error to client");
            this.setState({ loading: false });
            this.setState({ error: true})
        });


    };
   
  // Sets Search Filter as Text is entered.
  searchSpace=(event)=>{
    let keyword = event.target.value;
    this.setState({search:keyword})
  }

  // Sets Zone Filter on Button Click
  zoneSelect=(event)=>{
    let keyword = event.target.value;
    this.setState({zonefilter:keyword})
  }

    render() {
        const { loading } = this.state;
        const { error } = this.state;
        const { liftloading } = this.state;
        const { lifterror } = this.state;
        const { weatherloading } = this.state;
        const { weathererror } = this.state;
        var vTrails = this.state.vTrails;
        var vLifts = this.state.vLifts;
        var vWeather = this.state.vWeather;


        //console.log(vLifts)

        const mapTrails = vTrails
        .filter((vTrails) => {
            // Filter by Zone selection
            if (this.state.zonefilter == null || this.state.zonefilter === "All")
                return vTrails
            else if (vTrails.Zone.includes(this.state.zonefilter))
                return vTrails
        })
        .filter((vTrails) => {
            // Filter by Search Bar
            if (this.state.search == null)
                return vTrails
            else if(vTrails.Trail.toLowerCase().includes(this.state.search.toLowerCase()))
                return vTrails

        })
        .map(function(vTrails){
            return(
                // Table of Trails
                <React.Fragment key={vTrails.ID}>
                <tr>
                    <th id="difficultyicon">{vTrails.StatusIcon}</th>
                    <th>{vTrails.Trail}</th>
                    <th>{vTrails.Zone}</th>
                    <th id='difficultyicon'>{vTrails.Icon}</th>
                    <th id='difficultyicon'>{vTrails.GroomingIcon}</th>
                </tr>
               </React.Fragment>
            )
        })

        const mapLifts = vLifts
        .map(function(vLifts){
            return(
                // Table of Trails
                <React.Fragment key={vLifts.ID}>
                <tr>
                    <th id="difficultyicon">{vLifts.StatusIcon}</th>
                    <th>{vLifts.Lift}</th>
                    <th>{vLifts.Mountain}</th>
                </tr>
               </React.Fragment>
            )
        })

        const mapWeather = vWeather
        .map(function(vWeather){
            return(
                // Table of Trails
                <React.Fragment key={vWeather.ID}>
                <tr>
                    <th>{vWeather.Location}</th>
                    <th>{vWeather.CurrentTemp}</th>
                    <th>{vWeather.Updated}</th>
                </tr>
               </React.Fragment>
            )
        })

        // Sets Filtering Buttons as Active/Deactive for CSS purposes
        var btnElList = document.querySelectorAll('.FilterBtn');
        btnElList.forEach(btnEl => {
            btnEl.addEventListener('click', () => {
                document.querySelector('.buttonActive')?.classList.remove('buttonActive');
                btnEl.classList.add('buttonActive');
            })
        })

        return (
            <div className="today--section container">
                <div className="top--section container">
                    { lifterror ? 
                    // error is true:
                    <div className="columns top--section__box startbox">
                        <div className="column lifts--section">
                            <h4 id="error">Error gathering lift data...</h4>
                        </div>
                    </div>
                    :
                    // error is false:
                    <div className="columns top--section__box startbox">
                        <div className="column liftsend--section">
                        <h5>Lifts</h5>
                            <div id="tabsContainer" className="tabs"></div>
                            { liftloading ? <LoadingIndicator /> : 
                                <table>
                                    <thead>
                                        <tr>
                                            <th id="centerhead">Status</th>
                                            <th >Lift Name</th>
                                            <th >Mountain</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {mapLifts}
                                    </tbody>
                                </table>
                            }
                        </div>
                    </div>
                    }

                    { weathererror ? 
                    // error is true:
                    <div className="columns top--section__box endbox">
                        <div className="column lifts--section">
                            <h4 id="error">Error gathering lift data...</h4>
                        </div>
                    </div>
                    :
                    // error is false:
                    <div className="columns top--section__box endbox">
                        <div className="column liftsend--section">
                        <h5>Current Weather</h5>
                            <div id="tabsContainer" className="tabs"></div>
                            { weatherloading ? <LoadingIndicator /> : 
                                <table>
                                <thead>
                                    <tr>
                                        <th >Location</th>
                                        <th >Temperature</th>
                                        <th >Last Updated</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {mapWeather}
                                </tbody>
                            </table>
                            }
                        </div>
                    </div>
                    }


                </div>








                { error ? 
                // error is true:
                <div className="columns today--section__box">
                    <div className="column lifts--section">
                        <h4 id="error">Error gathering trail data...</h4>
                    </div>
                </div>
                :
                // error is false:
                <div className="columns today--section__box">
                    <div className="column liftsend--section">
                    <h5>Trails</h5>
                    <div className="BikeParkNotes">
                        <h3><u><b>Trail Notes:</b></u></h3>
                        <p>{this.state.vBikeParkNotes}</p>
                    </div>
                    <div className="filterbuttons">
                    {this.state.vZones &&
                        this.state.vZones.map((type, index) => (
                            <>
                            <button className="FilterBtn" key={index} value={type.Zone} onClick={this.zoneSelect}>
                                {type.Zone}
                            </button>
                            </>
                        ))}
                    </div>
                    <input id="searchbox" type="text" className="input is-rounded" placeholder="Enter Trail Name to Search" onChange={(e)=>this.searchSpace(e)} />
                        <div id="tabsContainer" className="tabs"></div>
                        { loading ? <LoadingIndicator /> : 
                            <table>
                                <thead>
                                    <tr>
                                        <th id="centerhead">Status</th>
                                        <th >Trail Name</th>
                                        <th >Zone</th>
                                        <th id="centerhead">Difficulty</th>
                                        <th id="centerhead">Work Done</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {mapTrails}
                                </tbody>
                            </table>
                        }
                    </div>
                </div>
                }
                <h6>Updated: {this.state.vDate}</h6>
                <p className='footerfont'>* Also accessible directly from top of Creekside, despite being labeled as Garbanzo Zone</p>
            </div>
        )
    }
}

export default SummerTrails
