// Import React and Component
import React, { Component, Fragment } from 'react';
import './App.css';
import Lifts from './Lifts/Lifts';
import Liftstest from './Lifts/Lifts-test';
import Snow from './Snow/Snow';
import Grooming from './Grooming/Grooming';
import Forecast from './Forecast/Forecast';
import BikeParkTrails from './Summer/BikeParkTrails';
import BikeParkAll from './Summer/BikeParkAll';
import Navbar from './Navbar';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import {RemoveScroll} from 'react-remove-scroll';

class App extends Component {
        constructor () {
            super();
            this.state = {
                season: "Winter",
                isSummer: false,
            };
        }

    componentDidMount() {
        const winterstartdate = "2024-11-01";
        const winterenddate = "2024-05-19";

        // Get Current Date and format
        var date = new Date();
        var year = date.toLocaleString("default", { year: "numeric" });
        var month = date.toLocaleString("default", { month: "2-digit" });
        var day = date.toLocaleString("default", { day: "2-digit" });
        var formattedDate = year + "-" + month + "-" + day;

        if (formattedDate >= winterenddate && formattedDate <= winterstartdate){
            //console.log("Summer");
            this.setState({ season: "Summer" });
            this.setState({ isSummer: true})
            }
        else {
            //console.log("Winter")
        };

      
    };
  
  render() {
    return (
        <div className="App">
            <Router>
                <div>
                    <div className="topheader">
                        <header className="container">
                            <Navbar />
                        </header>
                    </div> 
                    <Switch>
                        {/* <RemoveScroll> */}
                        { this.state.isSummer ?
                        // Summer is true
                            <Route component={BikeParkAll} exact path="/" />
                        // Summer is false
                        : 
                            <Route component={Lifts} exact path="/" />
                        }
                        <Route component={Snow} path="/snow" />
                        <Route component={Lifts} path="/lift" />
                        <Route component={Liftstest} exact path="/lifttest" />
                        <Route component={Grooming} path="/grooming" />
                        <Route component={Forecast} path="/forecast" />
                        <Route component={BikeParkTrails} path="/biketrails" />
                        <Route component={BikeParkAll} path="/bikepark" />                          
                        {/* </RemoveScroll> */}
                    </Switch>
                </div>
            </Router>
        </div>

    );
  }
}

export default App;
