import React, { Component } from 'react';
import './Grooming.css';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import CheckWinterSeasonOver from '../Shared/CheckWinterSeasonOver';
import InfiniteScroll from 'react-infinite-scroll-component';

const LoadingIndicator = props => {
    return (
    <div style={{
        width: "100%",
        height: "100",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
        }}
    >
    <Loader type="ThreeDots" color="var(--liftopenbox)" height="50" width="50" />
    </div>
    ) 
}

class Grooming extends Component {
    constructor () {
        super();
        this.state = {
            vTrails: [],
            vDate: '',
            loading: true,
            error: false,
            search:null,
        };
    }

    componentDidMount() {

        axios.get('https://fn.wblift.app/api/?type=terrain')
        
        .then(response => {
            var resp = [];
            resp = response.data.GroomingAreas;

            var TrailArray = [];

            response.data.GroomingAreas.forEach((el1, i1) => {
                var area = el1.Name;
                el1.Trails.forEach((el, i) => {
                    //console.log(el.Id, el.Name, area, el.Difficulty, el.IsOpen, el.IsGroomed)
                    if (el.Difficulty == "Green"){
                        var DifficultyIcon = "/images/greencircle.png"
                    }
                    if (el.Difficulty == "Blue"){
                        var DifficultyIcon = "/images/bluesquare.png"
                    }
                    if (el.Difficulty == "Black"){
                        var DifficultyIcon = "/images/blackdiamond.png"
                    }
                    if (el.Difficulty == "DoubleBlack"){
                        var DifficultyIcon = "/images/doubleblackdiamond.png"
                    }
                    if (el.Difficulty == "TerrainPark"){
                        var DifficultyIcon = "/images/terrainpark.png"
                    }
                    if (el.IsGroomed == true) {
                        var GroomIcon = "/images/checkmark.png"
                    }
                    if (area != "Backcountry Uphill Access Routes"){
                    TrailArray.push({ID: el.Id, Trail: el.Name, Zone: area, Difficulty: el.Difficulty, Icon: DifficultyIcon, isOpen: el.IsOpen, Groomed: el.IsGroomed, GroomingIcon: GroomIcon})
                    }
                })
            })

            this.setState({ vTrails: TrailArray })
            this.setState({ vDate: new Date(response.data.Date).toLocaleString('en-US',{timeZoneName: "short"})});
            this.setState({ loading: false }); 

            })
        .catch(error => {
            console.log(error);
            console.log("Error Here, sending Error to client");
            this.setState({ loading: false });
            this.setState({ error: true})
        });


    };

   

  searchSpace=(event)=>{
    let keyword = event.target.value;
    this.setState({search:keyword})
  }

    render() {
        const { loading } = this.state;
        const { error } = this.state;
        var vTrails = this.state.vTrails;

        const mapTrails = vTrails
        .filter((vTrails) => {
            if(this.state.search == null)
            return vTrails
            else if(vTrails.Trail.toLowerCase().includes(this.state.search.toLowerCase()))
            return vTrails
        })
        .map(function(vTrails){

            return(
                <React.Fragment key={vTrails.ID}>
                <tr>
                    <th>{vTrails.Trail}</th>
                    <th>{vTrails.Zone}</th>
                    <th id='difficultyicon'><img src={vTrails.Icon}></img></th>
                    {/* <th>{vTrails.Groomed.toString()}</th> */}
                    <th id='difficultyicon'><img src={vTrails.GroomingIcon}></img></th>
                </tr>
               </React.Fragment>
            )
        })


        return (
            <div className="today--section container">
                {/* <h2>Lift Information</h2> */}
                <CheckWinterSeasonOver />
                { error ? 
                // error is true:
                <div className="columns today--section__box">
                    <div className="column lifts--section">
                        <h4 id="error">Error gathering lift data...</h4>
                    </div>
                </div>
                :
                // error is false:
                <div className="columns today--section__box">
                    <div className="column liftsend--section">
                    <h5>Trails</h5>
                    <input id="searchbox" type="text" className="input is-rounded" placeholder="Enter Trail Name to Search" onChange={(e)=>this.searchSpace(e)} />
                        { loading ? <LoadingIndicator /> : 
                            <table>
                                <thead>
                                    <tr>
                                        <th >Trail Name</th>
                                        <th >Zone</th>
                                        <th id="centerhead">Difficulty</th>
                                        <th id="centerhead">Groomed</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {mapTrails}
                                </tbody>
                            </table>
                        }
                    </div>
                </div>
                }
                <h6>Updated: {this.state.vDate}</h6>
            </div>
        )
    }
}

export default Grooming
