import React, { useEffect, useState } from 'react';


const CheckWinterSeasonOver = () => {

    const [season, setSeason] = useState({
        season: "Winter",
        isSummer: false,

    });
    
        useEffect(()=>{
            const winterstartdate = "2024-11-22";
            const winterenddate = "2024-05-20";

            // Get Current Date and format
            var date = new Date();
            var year = date.toLocaleString("default", { year: "numeric" });
            var month = date.toLocaleString("default", { month: "2-digit" });
            var day = date.toLocaleString("default", { day: "2-digit" });
            var formattedDate = year + "-" + month + "-" + day;

            if (formattedDate >= winterenddate && formattedDate <= winterstartdate){
                //console.log("Summer");
                setSeason((season) => ({ 
                    season: "Summer",
                    isSummer: true }));}
            else {
                //console.log("Winter")
            };

          
        }, [] );
       
        return (
            <div>
                { season.isSummer ?
                // Summer is true
                    <div className="WinterClosed">
                    <h3>Notice: The Winter season has ended. This data is not updated during the Summer.</h3>
                    </div>
                // Summer is false
                : 
                    <></>
                }
            </div>
        );

};


export default CheckWinterSeasonOver;
