import React, { Component } from 'react';
//import './Forecast.css';
import axios from 'axios';
import convert from 'convert-units';
import Loader from 'react-loader-spinner';

const LoadingIndicator = props => {
    return (
    <div style={{
        width: "100%",
        height: "100",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
        }}
    >
    <Loader type="ThreeDots" color="#243133" height="50" width="50" />
    </div>
    ) 
}

class Forecast extends Component {

    constructor () {
        super();
        this.state = {
            loading: true,
            snowconditions: <LoadingIndicator />,
        };
    }

    componentDidMount () {
        this.setState({ loading: true }, () => {
            axios.get('https://cache.snow.com/api/weatherapi/GetWeather/WhistlerBlackcomb/')
            .then(response => {
                this.setState({ snowconditions: response.data.SnowConditions });
                this.setState({ loading: false });
            })
            .catch(error => {
                console.log(error)
            })
        })

    }

    render() {
        const { loading } = this.state;
        return (
            <div className="today--section container">
                {/* <h2>Snow Information</h2> */}
                {/* { loading ? <LoadingIndicator /> : */}
                <div className="columns today--section__box">
                    <div className="column forecast--section">
                        <h5>{this.state.newsnow12hours}</h5>
                        <p>12 Hours</p>
                    </div>
                    <div className="column forecast--section">
                        <h5>{this.state.newsnow24hours}</h5>
                        <p>24 Hours</p>
                    </div>
                    <div className="column forecast--section">
                        <h5>{this.state.newsnow48hours}</h5>
                        <p>48 Hours</p>
                    </div>
                    <div className="column forecastend--section">
                        <h5>{this.state.newsnow7days}</h5>
                        <p>7 Days</p>
                    </div>
                </div>
                {/* } */}

                <div className="columns today--section__box">
                    <div className="column forecast--section">
                        <h5>{this.state.basesnowmid}</h5>
                        <p>Base Snow</p>
                    </div>
                    <div className="column forecastend--section">
                        <h5>{this.state.seasonsnow}</h5>
                        <p>Season Total</p>
                    </div>
                </div>
                <h6>Updated: {this.state.reportdatetime}</h6>


            </div>
        )
    }
    
}

export default Forecast;